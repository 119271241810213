// import $ from 'jquery-slim';
// // const JQuery = $;
// import '@popperjs/core';
// import 'bootstrap';
// $.resolve();
$(function () {
    $("#covid-banner-close").on("click", (function (n) {
        $("#covid-banner").remove()
    }))
    $(".dropdown-menu.dropdown-menu-navbar").on("click", (function (n) {
        n.stopPropagation()
    }))
    $(".dropdown .accordion-button").on("click", (function (o) {
        o.stopPropagation()
    }))
    var i, tabsContent = document.querySelectorAll('#about-overview-mobile-tabs *[data-bs-toggle="tab"]');
    for (i = 0; i < tabsContent.length; ++i) tabsContent[i].addEventListener("shown.bs.tab", (function (t) {
        document.querySelector("#about-overview-mobile-menu-text").innerText = t.target.innerText
    }))
    var myCarousel = document.querySelector(".carousel.slide"),
        carousel = new bootstrap.Carousel(myCarousel);

    var root = $("#navbar-search-menu-id-toggler");
    root.on('click', (function () {
        root.hasClass("visible-search") ? ($("#navbar-search-menu-id-logo").show("fade"), $(
            "#navbar-search-menu-id-search").hide("fade"), $(
            "#navbar-search-menu-id-search-icon").show("fade"), $(
            "#navbar-search-menu-id-close-icon").hide("fade"), root.removeClass(
"visible-search")) : ($("#navbar-search-menu-id-logo").hide("fade"), $(
            "#navbar-search-menu-id-search").show("fade"), $(
            "#navbar-search-menu-id-close-icon").show("fade"), $(
            "#navbar-search-menu-id-search-icon").hide("fade"), root.addClass("visible-search"))
    }));
    $(".accordion-icon").on("click", (function (o) {
        o.stopPropagation()
        if($(this).data('active')){
            $(this).closest('.accordion-item').toggleClass('active');
        }
    }))
});